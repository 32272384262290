@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
:root{
--blue-theme:#77C9D4;
--theme-blue-trans:RGB(119, 201, 212,.8);
--aqua-theme:#57BC90;
--red-theme:#F53240;

--theme-clash:#F9CF00;
--theme-main:#4484ce;
--theme-complement:#F19F4D;
--theme-light-full:#D9D9D9;
--theme-light:rgba(217, 217, 217,.2);

--alt-blue:#4484ce;
--yellow:#F9CF00;
--tangerine:#F19F4D;
--aluminium:#D9D9D9;

--aqua:#008F95;
--mustard:#E9B000;
--papaya:#E24E42;

--warm-peach:#E24E42;
--summer-sky:#286DA8;
--field:#B37D4E;
--breeze:#438496;

--clean:#A7D2CB;
--butter:#F2D388;
--salmon:#C98474;
--eggplant:#874C62;

--fresh:#F7EF6A;
--vermillion:#93C178;
--sunshine:#465C8B;
--clean:#DFDCE3;
--pink-purple:rgba(237, 164, 230,.8);

--denim:#1A2930;

--cerulean:#7A9D96;

--main-green:var(--denim);
--light-blue:rgba(40, 109, 168, .9);
--main-yellow:var(--yellow);
--main-orange:var(--salmon);
--alt-background:rgba(40, 109, 168, .9);
--alt-text:var(--denim);

}

body, html {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color:var(--theme-light);
}

a{  
  color:var(--alt-text);
  text-decoration: none;
  transition: color .3ms ease-in-out;
}

a:focus{
  color:/*#77C9D4;*/ var(--warm-peach);
}

a:hover{
  color:var(--warm-peach);
  transition: color .1s ease-in-out;
}

textarea, input, .submit{
  outline:none;
  color:var(--denim);
  font-weight: 700;
  /*border:2px solid var(--red-theme);*/
  padding:1rem;
  border:none;
  border-radius: 10px;
  background-color: white;
}

.container{
  position:relative;
}

::placeholder{
  color:black;
}

.welcome{
  color:/*#CF6766;*/var(--alt-text);
  font-size:48px;
  padding:0 4rem;
}

.welcome-darker{
  color:/*#CF6766;*/var(--theme-main);
}

.beach-photo{
  width:100%;
  height:100%;
  object-fit:fill;
  color:black;
}

@media (max-width:480px){
  .beach-photo{
    object-fit: cover;
  }
}

.top{
  color:/*#CF6766;*/var(--alt-text);
  font-size: 48px;
  height:1300px;
  width:100%;
  position:relative;
  /*background-image: url('beach_graffiti_alghero.jpeg');*/
  /*background-image: url('beach_graffiti_alghero.png');
  object-fit: contain;
  background-size: contain;*/
  background-repeat:no-repeat;
}

/*.color-mode{
  border-radius:50%;
  height:20px;
  width:20px;
  position:relative;
  left:5%;
  top:10px;
  z-index:1;
}*/

.ciao, .myname,.thanks{
  animation-name: welcome;
  animation-duration:3s;
  animation-fill-mode:forwards;
  height:150px;
  width:150px;
  border-radius: 50%;
  text-align: center;
  display:flex;
  justify-content: center;
  align-items: center;
  position:absolute;
  color:var(--light-blue);
  opacity: .9;
  background-color: var(--main-yellow);
  margin: 10px;
}

#about{
  position:absolute;
  top:58%;
  display:flex;
  flex-direction:column;
  align-items: center;
  margin:50px;
}

@media (min-width:768px){
  #about{
    left:10px;
  }
}

@media (max-width:480px){
  #about{
    top:55%;
    left:0;
  }
}

.about-1,.about-2{
  background-color: greenyellow;
  display: inline;
  width:fit-content;
  opacity:.9;
  border-radius: 20px;
  padding:1rem 2rem;
  font-size: 32px;
  color:black;
  opacity:.2;
  animation-name: welcome;
  animation-duration:3s;
  animation-fill-mode:forwards;
  text-align: center;
}

@media (prefers-reduced-motion:no-preference){
  .about-1{
    transition:opacity 1.0s ease;
    }
}

@media (max-width:480px){
  .about-1{
    font-size: 24px;
    width:60%;
    margin-left:auto;
    margin-right:auto;
  }
}

.about-2{
  /*background-color: var(--red-theme);*/
  margin:3rem 4rem;
  top:540px;
  width:75%;
  font-size:22px;
  padding: 3rem 2rem;
  line-height: 1.2;
}

@media (min-width:768px){
  .about-2{
    width:60%;
  }
}

@media (max-width:480px){
  .about-2{
    width:60%;
    padding:1rem 1rem;
    margin-left:auto;
    margin-right:auto;
  }
}

@keyframes ciao-full {
  from {left:-12%; transform: rotate(0deg)}
  to {left:27%; transform:rotate(360deg)}
}

@keyframes ciao-480 {
  from {left:-100%; transform: rotate(0deg)}
  to {left:10%; transform:rotate(360deg)}
}

@keyframes ciao-768 {
  from {left:-100%; transform: rotate(0deg)}
  to {left:24%; transform:rotate(360deg)}
}

.ciao{
    top:30px;
    animation-name: ciao-480;
    animation-duration:1s;
    animation-fill-mode:forwards;
    @media(min-width:768px){
      animation-name: ciao-768;
    }
}

@media (max-width:480px){
  .ciao{
    animation-name:ciao-480;
  }
}

/*@keyframes myname {
  from {transform:scale(0);visibility:hidden;}
  to {transform:scale(1.0);visibility:visible}
}*/

.myname{
  left:47%;
  top:70px;
  width:180px;
  height:180px;
  animation-duration:2s;
  animation-delay: 1s;
  animation-name: welcome;
  animation-fill-mode:forwards;
  opacity:0;
  @media(min-width:768px){
    height:230px;
    width:230px;
    top:40px;
    left:55%;
  }
}

@media (max-width:480px){
  .myname{
    left:47%;
    top:70px;
    width:180px;
    height:180px;
  }
}

@keyframes thanks-full{
  from {left:-30%; transform: rotate(0deg)}
  to {left:33%; transform:rotate(360deg)}
}

@keyframes thanks-768{
  from {left:-30%; transform: rotate(0deg)}
  to {left:31%; transform:rotate(360deg)}
}

@keyframes thanks-480{
  from {left:-100%; transform: rotate(0deg)}
  to {left:20%; transform:rotate(360deg)}
}

.thanks{
  height:250px;
  width:250px;
  animation-duration:1s;
  animation-delay: 2s;
  animation-name: thanks-480;
  animation-fill-mode:forwards;
  font-size: 40px;
  left:-100%;
  top:20%;
  font-size:68px;
  @media(min-width:768px){
    top:16%;
    animation-name: thanks-768;
  }
}

@media (min-width:768px){
  .thanks{
    animation-name: thanks-768;
  }
}

@media (max-width:480px){
  .thanks{
    top:20%;
    animation-name: thanks-480;
  }
}

.dark{
  background-color: black;
}

.light{
  background-color:white;
}


.header,.footer{
  padding:3rem; 
  display:flex;
  justify-content:flex-end;
  column-gap:15px;
  row-gap:10px;
  background-color: rgba(197, 193, 192, .2);
  font-weight: 800;
}

.header{
  position:relative;
  border-bottom: 2px solid var(--theme-main);
  /*background-color: #A5A5AF;*/
}

.footer{
}

.header_links{
  display:flex;
  column-gap:15px;
  position:absolute;
  animation-name: header-full;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 2s;
  bottom:40%;
  transform:translateX(150%);
}

@keyframes header-full {
  from{ transform:translateX(100%)}
  to{transform:translateX(0%)}
}

.hide_head{
  overflow:hidden;
  position: relative;
  left:100%;
}

#previous_work{
  /*border-top: 2px solid var(--theme-main);*/
  padding-top:4rem;
  background-color:white;
  color:black;
}

.card_div{
  width:100%;
  display:flex;
  justify-content: space-around;
  align-items:center;
  margin:4rem 0;
  column-gap: 10px;

}

@media (max-width:860px){
  .card_div{
    flex-direction:column;
    row-gap: 35px;
  }
}

.card{
  border-radius: 5px;
  /*padding:1rem;*/
  background-color:var(--main-green);
  /*background-image:url('dymaco-card-pic.png');
  background-size: cover;
  background-position: center;
  background-repeat: none;*/
  opacity:.75;
  display:flex;
  flex-direction: column;
  color:white;
  height: 300px;
  width: 325px;
  transition: all .5s ease-in-out;
  align-items: center;
  position:relative;
  column-gap: 5px;
}

.card_button{
  width:65px;
  height:65px;
  border-radius:50%;
  background-color:var(--denim);
  position:absolute;
  top:33%;
  color:white;
  z-index:200;
  display:flex;
  justify-content:center;
  align-items: center;
  transition: all .5s ease-in-out;
  opacity:1.0;
  border: 2px solid white;
}

.card_button:hover{
  color:var(--denim);
  border: 2px solid var(--denim);
  background-color: white;
  opacity:.6;
}

.card_img{
  height:150px;
  width:100%;
  object-fit: cover;
}

.card:hover{
  opacity:1.0;
}

.card>a{
  color:var(--red-theme);
  padding-bottom:10px;
}

.card_details{
  font-weight:300;
  font-size:12px;
  text-align:center;
  padding:.5rem;
}

.form{
  border-radius: 5px;
  display:grid;
  grid-template-columns: 50% 50%;
  row-gap: 10px;
  column-gap: 10px;
  width:90%;
  opacity: .7;
  margin-top: 10px;
  opacity:.9;
  transition: all .5s ease-in-out;
  /*@media(min-width:768px){
    grid-template-columns: 300px 300px;
  }*/
}

.form:hover{
  opacity:.9;
}

@media (max-width:480px){
  .form{
    grid-template-columns: 100px 100px;
  }
}

.name, .email, .msg, .submit{
  border-radius: 10px;
  background-color:white;
}
.submit{
  font-size:22px;
}

.name{
 grid-column: 1/2;
}

.email{
  grid-column: 2/2;
}

.msg{
  grid-column: 1/3;
}

.submit:hover{
  transition: all ease-in-out .8s;
  background-color:var(--denim);/* rgba(245, 50, 64, .8); */
  color:white;
}

@media (max-width:480px){
  .submit{
    grid-column: 1/3;
  }
}

#contact{
  padding:8rem 3rem;
  /*background-color:greenyellow;*/
  background-image: url('photos/sunset-gw.jpeg');
  background-size: cover;
  background-repeat:no-repeat;
  background-position: center; 

}

.pie-chart{
  height:100px;
  width:100px;
  border-radius: 50%;
  background: conic-gradient(blue 50.00%, red 50.00%, black);
}

@keyframes welcome {
  from{ opacity:0}
  to{opacity:.9}
}

.white_text{
  color:white;
  font-size: 72px;
}

.blue_bgc_white_txt,.green_bgc_denim_txt{
  background-color: var(--light-blue);
  color:white;
  padding:2rem 2rem;
}

@media (max-width:480px){
  .green_bgc_denim_txt>div{
    font-size: 36px;
    margin-bottom: 20px;
  }
}

.blue_bgc_white_txt{
  text-align: center;
}

.green_bgc_denim_txt{
  background-color: greenyellow;/*rgba(26, 41, 48, .6);/*#2B7C87*/;
  color:var(--denim);
  opacity:.9;
  border-radius:15px;
  padding:4rem 3rem;
}

.extra_padding{
  padding: 2rem 3rem;
  @media(min-width:768px){
    padding:4rem 6rem;
  }
}

@media (max-width:480px){
  .extra_padding{
    padding:2rem 3rem;
  }
}

.extra_vertical{
  padding-top:2rem;
  padding-bottom:2rem;
}

.letter-container{
  width: 600px;
  height:300px;
  background-color: black;
  display:grid;
  grid-template-columns: repeat(5, 1fr);
  padding:1rem;
  column-gap:10px;

}

.letters{
  display:flex;
  border:slategrey 1px solid;
  background-color: #3a3a3c;
  height:100px;
  text-align: center;
  color:white;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: 800;
}